export const clientNameFooter = "GYROTONIC®, GYROTONIC® & Logo, GYROTONIC EXPANSION SYSTEM® and GYROKINESIS® são marcas registradas e são utilizadas com a autorização da Gyrotonic Sales Corp";
export const titleMain = "A natureza nos oferece todo o potencial, mas precisamos fazer a nossa parte.";
export const subtitleMain = "Juliu Horvath";

export const aspectRatioLogoAbout = '2'; // width/height
export const aspectRatioLogoMain = '1'; // width/height

export const borderRadiusButtons = '10px';

export const nomeFornecedorCompleto = "Atelier do Movimento Corporal LTDA";
export const nomeFornecedor = "Atelier do Movimento Corporal";
export const artigo = "a";
export const artigoCaps = "A";

export const footerSocialData = [
  {
    type: "material-community",
    name: "whatsapp",
    link: "https://wa.me/5512997166559",
    number: "(12) 997166559"
  },
  {
    type: "material-community",
    name: "instagram",
    link: "https://www.instagram.com/fabi_bernardes/",
    profile: "@fabi_bernardes"
  },
];

export const drawerClosedLinks = [
  {
    title: "Fale Comigo",
    link: "https://wa.me/5512997166559",
  },
];

export const linkAppleStore = null
export const linkPlayStore = null

export const hasOutsideAboutPage = false;
export const drawerOpenedLinks = [];