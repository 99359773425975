import { RFValue } from "react-native-responsive-fontsize";

export default {
  COLORS: {
    ALERT: "#ec1414",
    ICON: '#DB9A8F', //COR DOS CADEADOS E DAS SETAS PARA O LADO
    ICON_HEADER_PURCHASE: "#814256", //COR DAS SETINHAS 
    BACKGROUND_HEADER: "none", //COR DE TODOS OS CABEÇALHOS
    //TELA CLICKCOURSE, PLANS e SIGNUP
    ICON_HEADER: "#2e2e2e",  //COR DA PALAVRA ESCOLHER COMPRA, CADASTRAR-SE E COMPLETAR PAGAMENTO e VOLTAR da EDITPROFILE

    //CORES GERAIS
    PRIMARY_900: "#814256", //COR PRINCIPAL DOS DETALHES DO APP - CHECKBOX, FECHAR TERMOS DE USO, ETC
    PRIMARY_800: "#9C5A6E", //COR PRINCIPAL MAIS CLARA PARA INPUT LOGIN E ACTIVITY INDICATOR
    PRIMARY_700: "#F2E9E1", //COR MAIS CLARA DO CLIENTE = COR PRINCIPAL MAIS CLARA PARA INPUT LOGIN E ACTIVITY INDICATOR
    TEXT_BUTTON: "#F2E9E1", //USADA PARA O TEXTO DOS BOTÕES LOGIN, CLICKCOURSE, SIGNUP - COR DE FUNDO É A PRIMARY_900

    //CORES DO DRAWER
    DRAWER_SELECTED: "#814256", //COR DRAWER CLICADO E DO TEXTO DO DRAWER

    //TELAS: LOGIN, CLICKCOURSE, SIGNUP E PLANS
    BACKGROUND_ABOUT: "#F2E9E1", //COR PARA A ABOUT, DRAWER, CLICK COURSE, PLANS, EDITPROFILE, LOGIN E CADASTRO
    TITLE_ABOUT: '#814256', //TAMANHOS EXTRABIG, BIG E STANDARD
    TEXT_ABOUT: '#814256', //TAMANHOS MEDIUM, SMALL E EXTRASMALL

    //TELA TERMOS DE USO
    BACKGROUND_TERMS: "#FFFFFF", //COR PARA O FUNDO DOS TERMOS

    //PLANS
    S5_BACKGROUND: "#F2E9E1",
    S5_BACKGROUND_SELECTED: "#ECCC6E", //COR DE FUNDO DO ITEM SELECIONADO DA FLATLIST MENSAL, TRI, ETC
    S5_BACKGROUND_NO_SELECTED: "transparent", //COR DE FUNDO DO ITEM NAO SELECIONADO DA FLATLIST MENSAL, TRI, ETC
    S5_TEXT_SELECTED: "#814256", //COR DO TEXTO DO ITEM SELECIONADO DA FLATLIST MENSAL, TRI, ETC
    S5_TEXT_NO_SELECTED: "#814256", //COR DO TEXTO DO ITEM NAO SELECIONADO DA FLATLIST MENSAL, TRI, ETC
    
    //TELA MAIN
    ICON_HEADER_CLICKCLASS: "#FFFFFF", //COR DO ÍCONE VOLTAR DA CLICKCLASS
    ICON_HEADER_MAIN: "#FFFFFF", //COR DO ICONE DRAWER
    BACKGROUND_MAIN: "#814256", //COR PARA A MAIN E CLICKCLASS
    TITLE_OVERPHOTO_MAIN: "#F2E9E1", //USADA PARA O TÍTULO E SUBTÍTULO DA MAIN
    TITLE_MAIN: "#ECCC6E", //USADA PARA PARA OS TAMANHOS EXTRABIG, BIG E STANDARD
    TEXT_MAIN: "#F2E9E1", //USADA PARA PARA OS TAMANHOS MEDIUM, SMALL E EXTRASMALL

    //FOOTER TELA ABOUT E DRAWER
    BACKGROUND_FOOTER: "#ECCC6E", //COR DO RODAPÉ
    TEXT_FOOTER: "#814256", //USADA PARA O TEXTO DO RODAPÉ

    GRADIENT_MAIN: [
      "rgba(0,0,0,0.5)",
      "rgba(0,0,0,0.1)",
      "rgba(0,0,0,0.1)",
      "#814256",
    ],

    //BOTÕES ARQUIVAR E PUBLICAR
    BACKGROUND_PUBLISH: "#ECCC6E", //COR DO RODAPÉ
    BACKGROUND_ARCHIVE: "#F2E9E1", //COR DO RODA
    TEXT_PUBLISH: "#672A3F", //USADA PARA O TEXTO DO RODAPÉ
    TEXT_ARCHIVE: "#672A3F", //USADA PARA O TEXTO DO RODAPÉ
  },

  FONTFAMILY: {
    BOLD: "text_Bold",
    MEDIUM: "text_Medium",
    REGULAR: "text_Regular",
    LIGHT: "text_Light",
    TITLE: "text_Title",
    SUBTITLE: "text_Subtitle",
    TEXTS: "text_Texts"
  },
  FONTSIZE: {
    EXTRABIG: RFValue(30, 980), //USADA 1X NA COURSES
    BIG: 26,
    STANDARD: 20,
    MEDIUM: 18,
    SMALL: 16,
    EXTRASMALL: 13,
  },
};